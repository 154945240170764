<template>
	<div class="nfz1NBFeLVm6IIOo2CLj promote abensi">
		<!--  -->
		<van-nav-bar
		  :title="`Convide uma pessoa e ganhe bônus de R$${shareInfo.bonus}`"
		  left-arrow
		  fixed
		  placeholder
		  right-text="Histórico"
		  @click-left="onClickLeft"
		  @click-right="openUrl('records')"
		/>
		<div class="hrp2W0A6qvg1zg4ysky3 boxShadow theme-box-shadow-h5">
			<section class="MZG2AG46nbxCf7Pj94bu">
			
				<div class="skuC1f1NURrkI5OOZ7Ju">
					
					<div class="tbJe4aWRACpPqK8U19l3">
						Informações da promoção 		
					</div>
				</div>
				<!---->
			</section>
			<!-- _MeuLink:"Meu Link",
								 	 _MododeAgente:"Modo de Agente",
									 _RededoAgente:"Rede do Agente",
									 _Nœud:"Différence de niveau infini (Nœud de jour)",
								 	 _Coletável:'Coletável',
									 _Subordinadosdiretos:"Subordinados diretos",
									 _CódigodeConvite:"Código de Convite",
									 _Receber:"Receber",_Histórico:"Histórico",_CliqueparaSalvar:"Clique para Salvar", -->
			<!-- {{shareInfo.open_tips}} -->
			<div
				class="ant-spin-nested-loading URYVffVETVSIkPGB9P5E custom-loading__wrap ye40pj_izwzKAVwAomfq QBeJPwd_uejjKLAsjWcb">
				<div class="ant-spin-container">
					<div class="HuBHjdDhrs3shwX2I3cf">
						<div class="Cp3sCgsE5FnXQBFpShix">
							<div class="ant-spin-nested-loading URYVffVETVSIkPGB9P5E custom-loading__wrap">
								<div class="ant-spin-container">
									<div class="_197iSp6iuweVeX9jjWqU">
										<canvas ref="qrcode_Canvas"></canvas>
										<!-- <img src="" style="display: inline-block;"> -->
										<div class="ant-btn ant-btn-link">
											{{ $t('promote._Receber') }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="X7q4mjP_LFhFIJRXfYuM">
							<div class="qiuoPrJlJq7AoDdC_Jp1">
								<div class="uQRA1q9mzX1o2pAJ5uZA"><span>{{ $t('promote._MeuLink') }}</span></div>
								<div class="Z1AgDMke12GSrmYdP6eE" @click="copyContent(`${origin}/?id=${userInfo.id}&type=2`)">
									<div tabindex="0" class="ant-select ant-select-enabled">
										<div role="combobox" aria-autocomplete="list" aria-haspopup="true"
											aria-controls="d2672658-ada8-465f-e0a4-c7a27a9c1756"
											class="ant-select-selection ant-select-selection--single">
											<div class="ant-select-selection__rendered"
												>
												<div class="ant-select-selection-selected-value"
													style="display: block; opacity: 1;">
													{{origin}}/?id={{userInfo.id}}&type=2
												</div>
											</div>
											<!-- <span unselectable="on" class="ant-select-arrow"
												style="user-select: none;"><span id="myLink-suffixIcon"
													class="ant-select-arrow-icon">Alterar</span></span> -->
										</div>
									</div>

									<span class="PmwtECGo9wfGBMP0uy71 copyIcon COsess8CMJ_BLuGwYYdj">
										<svg-icon className="imageNav-a" style="width: .24rem;height: .24rem;"
											icon-class="comm_icon_copy--svgSprite:all" />
									</span>
								</div>
							</div>
							<div class="qodAUmIjz1fK0NTjDSLf">
								<div><label>Subordinados diretos</label><span
										class="dOhCK6tlyL9yiDmj_NGc">{{shareInfo.subordinate}}</span>
								</div>
								<div @click="copyContent(userInfo.id)"><label>Código de
										Convite</label><span>{{userInfo.id}}</span><span
										class="PmwtECGo9wfGBMP0uy71 copyIcon COsess8CMJ_BLuGwYYdj">
										<svg-icon style="width: .24rem;height: .24rem;"
											icon-class="comm_icon_copy--svgSprite:all" />
									</span></div>
							</div>
						</div>
						<div class="my-scrollbar-content">
							<div class="cX3I4wr8lagPbzMNAc5i">
								<div><span class="zsbbPf1TFxnlj15CZdFo">
										<svg-icon className="imageWrap" icon-class="icon_tg_share--svgSprite:all" />
									</span><span>Partilhar</span></div>
								<div><img src="./../../assets/kuaijiexiaz/img_tg.png" alt="Telegram" data-blur="0"
										data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Telegram</span></div>
								<div><img src="./../../assets/kuaijiexiaz/img_facebook.png" alt="Facebook" data-blur="0"
										data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Facebook</span></div>
								<div><img src="./../../assets/kuaijiexiaz/img_wa.png" alt="WhatsApp" data-blur="0"
										data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>WhatsApp</span></div>
								<div><img src="./../../assets/kuaijiexiaz/1795519195972894722.png" alt="Instagram"
										class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Instagram</span></div>
								<div><img src="./../../assets/kuaijiexiaz/img_line.png" alt="Line" data-blur="0"
										data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Line</span></div>
								<div><img src="./../../assets/kuaijiexiaz/1795519414047723522.png" alt="Twitter"
										class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Twitter</span></div>
								<div><img class="goDRiiBsuEuXD3W1NphN"
										src="./../../assets/kuaijiexiaz/1795519529549303810.png"
										style="width: 0.7rem; height: 0.7rem;"><span>Youtube</span></div>
								<div><img src="./../../assets/kuaijiexiaz/1795519631237173249.png"
										class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Tiktok</span></div>
								<div><img src="./../../assets/kuaijiexiaz/1795519724854632449.png"
										class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Kwai</span></div>
							</div>
							<!-- -->
							<div @click="sfunCtibe" class="YkUDamxGw59tDTcHM7e9"><div class="OpYs0fj4ciSUtyFvmBjG"><div class="AXpdRpeSZIHh8kGQWu94"><span class="W8CzNiFMKbfGltRrcCis">Subordinados válidos <span>{{shareInfo.valid_subordinate}}</span> pessoas</span></div><div class="W8CzNiFMKbfGltRrcCis"><span>Detalhes</span></div></div><div class="OpYs0fj4ciSUtyFvmBjG"></div></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--  -->
		<div style="padding: .2rem;padding-bottom: 0;">
			<section class="hBXG33WPJjXOwqg4Qi91">
				<div class="TUSPUZVxUlSsX6cnNkWa"><span><span class="N4LsWhJBhqqyTkv6V0NE auto-shrink-text"
							style="width: 100%; margin-bottom: -1.29176px; padding-bottom: 1.29176px;"><span
								class="ke1kCzBARxq5HSxbDQ6h" style="font-size: 10.98px;"><span
									class="TMZWYZcIv5YQXVdFMVrw inner-text" style="-webkit-line-clamp: 2;font-size: .24rem;">O que é um
									número válido promocional?(Cumprir todos os requisitos indicados
									abaixo)</span></span></span></span></div>
				<div class="xmTBy8GFpn1k9Bb9QLt_" >
					
					<div class="NyWAKBXfO74hdgDTMrVS" v-if="shareInfo.rule.recharge"><span>Depósitos acumulados do subordinado</span><span>{{shareInfo.rule.recharge}} ou
							mais</span></div>
					<div class="NyWAKBXfO74hdgDTMrVS" v-if="shareInfo.rule.bet"><span>Apostas acumuladas do subordinado</span><span>{{shareInfo.rule.bet}} ou
							mais</span></div>
				</div>
			</section>
		</div>

		<!--  -->
		<div class="y9ILWSVwXtmlSiqyeCz8 " style="padding: .2rem;">
			<div>
				<div class="B9LmeSQOfyyQDEZqRAdm tutorialDetail">
					<div class="ant-spin-nested-loading URYVffVETVSIkPGB9P5E custom-loading__wrap">
						<div class="ant-spin-container">
							<div class="eOKOIr3zSV1KpjZLu6bU">								
								<!-- DHkNZATZWTkEcsdUArGN -->
								<div class="Uurf2eE1EvJ8taIjSBAx" v-for="(item,index) in shareInfo.chunked" >
									<div class="J9w_6tuODPdBjAKYjzRB" >
										<span  v-for="(item_a,inex_a) in item" :key="inex_a" style="display: flex;align-items: center;">
											<div class="cdTm7WAp1DzSFJtpaZBg" style="cursor: default;">
												<div class="tVWMurTJ0hgGdLJOcBwM">
													<div class="goDRiiBsuEuXD3W1NphN YRQ2J6iR5UsUbKRNAOVQ"
														style="width: 1.32rem; height: 1.23rem; background-position: 0px 0px; 
														
														 background-size: 100% 100%;"
														 :style="{
															 'background-image': `url(&quot;https://cdntoos.amei777.im/siteadmin/skin/lobby_asset/common/common/event/${shareInfo.release_subordinate>=item_a.num?'tghd_img_bx2':shareInfo.valid_subordinate>=item_a.num?'tghd_img_bx1':'tghd_img_bx3'}.webp?manualVersion=1&version=ab57a311c5&quot;)`
														 }"
														 >
													</div>
													<div class="cd4GJbR3Wd3qG_LfsF9Q">
														<div class="a7rMwyv8RGb4nno93pZw "><span
																class="N4LsWhJBhqqyTkv6V0NE auto-shrink-text"
																style="width: 100%; margin-bottom: -0.941176px; padding-bottom: 0.941176px;"><span
																	class="ke1kCzBARxq5HSxbDQ6h"
																	style="font-size: 8px;"><span
																		class="TMZWYZcIv5YQXVdFMVrw inner-text"
																		style="-webkit-line-clamp: 2;">{{item_a.num}}
																		pessoas</span></span></span></div>
													</div>
												</div>
												
												<div class="NCbu1Mch6PC7N3vHu7nr">
													<span v-if="!(shareInfo.release_subordinate>=item_a.num)"  :class="{'cJ15xW5ihZdp5DhKL6rX':shareInfo.valid_subordinate>=item_a.num}">{{item_a.bonus}}</span>
													<span style="position: absolute;top:.45rem;color:var(--theme-secondary-color-error);" v-else>{{item_a.bonus}}</span>
												</div>
											</div>
											<!-- {{inex_a}}{{index}} -->
											<div class="T5W9naENOMXV_994Fe8b WPc2UbH4eWvd7rjuL71e" v-if="index%2===0?(inex_a<=2):inex_a<=3&&inex_a!=0">
												<svg-icon style="width: .22rem;height: .22rem;" icon-class="img_tghdjt--svgSprite:all" />
											</div>
										</span>
									</div>
									<div class="UCM1fIOkrXgoV8tKpWaA WPc2UbH4eWvd7rjuL71e" v-if="shareInfo.chunked.length!=index+1">
										<svg-icon style="width: .22rem;height: .22rem;"
											icon-class="img_tghdjt--svgSprite:all" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<div class="QV03qBSNYehJplfhfCcw">
			<div class="Zw36nTThA8kC3264AaHK">
				<span>Rede do Agente</span>
			</div>

			<div class="tutorialDetail" v-html="shareInfo.desc">

			</div>
		</div>
		<!--  -->
		<fenxiang ></fenxiang>
	</div>
</template>

<script>
	import {
		shareIndex
	} from "@/apis/modules/share.js";
	import QRCode from 'qrcode';
	import fenxiang from "./components/fenxiang.vue";
	import {
		mapState
	} from 'vuex';
	export default {
		components:{
			fenxiang
		},
		computed: {
			...mapState(['popList', 'userInfo','isDireto'])
		},
		data() {
			return {
				shareInfo: {},
				// {{origin}}/?id={{userInfo.id}}
				origin: window.location.origin,
			}
		},
		mounted() {
			this.shareIndex();
		},
		methods: {
			sfunCtibe(){
				this.$store.commit('publicFun',['isDireto',{
					LinkdeConviteEvent:true,
					ruleRecharge:this.shareInfo.rule.recharge,
					relebet:this.shareInfo.rule.bet
				}]) 
			},
			shareIndex() {
				shareIndex({
					activity: 1,
				}).then(({
					data
				}) => {
					let arr=[];
					function chunkArray(arr, chunkSize) {
					  let temp = [];
					  for (let i = 0; i < arr.length; i += chunkSize) {
					    temp.push(arr.slice(i, i + chunkSize));
					  }
					  return temp;
					}
					 
					// 示例
					// const arrAr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
					const chunked = chunkArray(data.list, 4);
					console.log(chunked);
					// data.list.forEach((item,index)=>{
						
					// })
					
					this.shareInfo ={
						...data,
						chunked
					} ;
					 // v-for="(item,index) in  shareInfo.list"
					this.generateQRCode();
				})
			},
			onClickLeft(){
					 this.$router.go(-1);
			},
			generateQRCode() {
				// let width = this.width,
				//         height = this.height;
				//       QRCode.toCanvas(
				//         document.getElementById(this.canvasId),
				//         this.content,
				//         { width, height, toSJISFunc: QRCode.toSJIS },
				//         error => {}
				//       );

				const canvas = this.$refs.qrcode_Canvas;
				QRCode.toCanvas(canvas, `${this.origin}/?id=${this.userInfo.id}`, {
					width: 78,
					height: 78,
					toSJISFunc: QRCode.toSJIS
				}, error => {
					if (error) console.error(error, 'QRCode')
				})
			},
		},
		created() {

		}
	}
</script>
<style>
	.nfz1NBFeLVm6IIOo2CLj .van-nav-bar__title{
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		line-height: 1.2;
		overflow: hidden;
		overflow: initial;
		text-align: center;
		text-overflow: ellipsis;
		white-space:initial;
		vertical-align: middle;
		word-break: break-word;
	}
	.van-nav-bar__text{
		    color: var(--theme-ant-primary-color-0);
	}
</style>
<style scoped>
	html .y9ILWSVwXtmlSiqyeCz8 {
		font-size: .22rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .B9LmeSQOfyyQDEZqRAdm {
		padding: .2rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .RDVBXpScbYLUeqahO4q5 {
		border-bottom: .01rem solid var(--theme-color-line);
		padding-bottom: .2rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .RDVBXpScbYLUeqahO4q5 .bTkyl5eQr3uEIZ8Dgnfw .TMR0c4Pr6f5L9E34buzp {
		font-size: .24rem;
		line-height: .31rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .RDVBXpScbYLUeqahO4q5 .bTkyl5eQr3uEIZ8Dgnfw .ukboJfGL8gpiGdbhBxpJ {
		font-size: .24rem;
		line-height: .31rem;
		margin-top: .1rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .Y_O3FEo6SMH4_bFHMWF8 {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex
	}

	html .y9ILWSVwXtmlSiqyeCz8 .Y_O3FEo6SMH4_bFHMWF8 button+button {
		margin-left: .2rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU {
		margin-top: 0;
		padding-bottom: 0;
		padding-top: .19rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .Uurf2eE1EvJ8taIjSBAx {
		padding-top: .12rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .Uurf2eE1EvJ8taIjSBAx:nth-child(2n) .J9w_6tuODPdBjAKYjzRB {
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .Uurf2eE1EvJ8taIjSBAx:nth-child(2n) .T5W9naENOMXV_994Fe8b {
		-webkit-transform: rotateY(180deg);
		transform: rotateY(180deg)
	}
	
	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .Uurf2eE1EvJ8taIjSBAx:nth-child(2n) .UCM1fIOkrXgoV8tKpWaA {
		-ms-flex-pack: start;
		justify-content: flex-start
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .Uurf2eE1EvJ8taIjSBAx:nth-child(2n) .UCM1fIOkrXgoV8tKpWaA svg {
		margin-left: .57rem;
		margin-right: 0
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg {
		position: relative;
		width: 1.32rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .cd4GJbR3Wd3qG_LfsF9Q {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .a7rMwyv8RGb4nno93pZw {
		bottom: .1rem;
		color: var(--theme-text-color-activities-text);
		font-size: .18rem;
		height: .4rem;
		padding: 0 .21rem;
		line-height: .2rem;
		text-align: center
	}
	.OpYs0fj4ciSUtyFvmBjG{
		display: flex;
		padding-bottom: .2rem;
	}
	.YkUDamxGw59tDTcHM7e9{
		font-size: .22rem;
		display: flex;
	}
	.W8CzNiFMKbfGltRrcCis{
		display: flex;
		    color: var(--theme-text-color-lighten);
	}
	 .W8CzNiFMKbfGltRrcCis span {
	    color: var(--theme-primary-color);
	    cursor: pointer;
	    padding: 0 .05rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .DHkNZATZWTkEcsdUArGN {
		color: var(--theme-secondary-color-error);
		height: .3rem;
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: .4rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .DHkNZATZWTkEcsdUArGN>div {
		padding: 0 .12rem;
		width: 100%
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .NCbu1Mch6PC7N3vHu7nr {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		height: .5rem;
		justify-content: center;
		width: inherit;
		word-break: break-all
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .NCbu1Mch6PC7N3vHu7nr span {
		font-size: .2rem;
		line-height: 1;
		text-align: center;
		word-break: break-all
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .NCbu1Mch6PC7N3vHu7nr .ZDY1xupThQauLYZh_Erf {
		-webkit-transform: scale(.8) !important;
		transform: scale(.8) !important
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .YRQ2J6iR5UsUbKRNAOVQ {
		position: relative
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .YRQ2J6iR5UsUbKRNAOVQ .cdnExGkUiUfiXoGqHOBT {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		left: .54rem;
		position: absolute;
		top: .12rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .YRQ2J6iR5UsUbKRNAOVQ .j2sKlwXzVOYqvsajUckQ {
		top: .34rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA {
		-ms-flex-pack: end;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-end;
		margin-top: .12rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA svg {
		margin-right: .57rem;
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg)
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA.uXgHB6pqx_7dqw8J3tIz {
		margin-bottom: .2rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .T5W9naENOMXV_994Fe8b {
		display: block;
		padding: 0 .126rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .T5W9naENOMXV_994Fe8b,
	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA {
		color: var(--theme-secondary-color-finance)
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .T5W9naENOMXV_994Fe8b svg,
	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA svg {
		height: .2rem;
		width: .22rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .T5W9naENOMXV_994Fe8b.WPc2UbH4eWvd7rjuL71e,
	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA.WPc2UbH4eWvd7rjuL71e {
		color: var(--theme-text-color-lighten)
	}

	html .y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .FwAtuvnbsAew7ub5himj {
		margin-top: -.5rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .d78ViD_BdpXbjKZLRKiR .w_lD6NY_8fbzHjmPUrM5 .vqAJ4GsA3hJgqWxBhy2q .wRNMUAfWZpv5hjCuZCPS {
		font-size: .2rem;
		height: .5rem;
		width: 1.2rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .USQnz1ZNZDBrtxSu3oKT .CbGxrJFW8nwDH_FIXeOl {
		height: .7rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .mSAHm2ZEeMguccNUkCfy .RsF419YaltdgXdOYssLA {
		font-size: .24rem;
		height: .75rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .soX9bHhwBjkGhc08cp7Y,
	html .y9ILWSVwXtmlSiqyeCz8 .soX9bHhwBjkGhc08cp7Y .g3KCtj4Z4Rkgkotlk45n {
		display: -ms-flexbox;
		display: flex
	}

	html .y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X>:first-child {
		font-size: .22rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X>:nth-child(2) input {
		height: .7rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X>:nth-child(2)>span {
		font-size: .3rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .RdrkTa7b5kgMC9G30aDx {
		background-color: rgba(0, 0, 0, .5);
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 999
	}

	html .y9ILWSVwXtmlSiqyeCz8 .RdrkTa7b5kgMC9G30aDx .jf8fllwvQJKt_pHvNwie {
		height: 100%;
		position: relative;
		width: 100%
	}

	html .y9ILWSVwXtmlSiqyeCz8 .RdrkTa7b5kgMC9G30aDx .jf8fllwvQJKt_pHvNwie img {
		left: 50%;
		margin-left: -2.09rem;
		position: absolute;
		top: 2.74rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 {
		background-color: var(--theme-main-bg-color);
		border-top-left-radius: .14rem;
		border-top-right-radius: .14rem;
		bottom: -3.34rem;
		height: 3.34rem;
		left: 0;
		position: fixed;
		-webkit-transform-origin: top;
		transform-origin: top;
		width: 100%;
		z-index: 1000
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .WqReVWUwGHl6bMIBFlIq {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		border-bottom: .01rem solid var(--theme-color-line);
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		font-size: .3rem;
		height: .89rem;
		justify-content: center;
		width: 100%
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .OcuOujWTdm6_XyP_Zkzv .Z9Z8NJqAL5GmtGLeXZWA {
		-ms-flex-align: start;
		align-items: flex-start;
		cursor: pointer;
		display: -ms-flexbox;
		display: flex;
		min-height: .85rem;
		padding: .2rem 0
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .OcuOujWTdm6_XyP_Zkzv .Z9Z8NJqAL5GmtGLeXZWA .C3O6IJupokjdgb9MsP5C span {
		font-size: .26rem !important
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .OcuOujWTdm6_XyP_Zkzv .Z9Z8NJqAL5GmtGLeXZWA .C3O6IJupokjdgb9MsP5C .RK4WE8a2P77YoDB9oImy {
		color: var(--theme-primary-color);
		font-size: .26rem;
		margin: .1rem 0 .02rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .OcuOujWTdm6_XyP_Zkzv .Z9Z8NJqAL5GmtGLeXZWA .C3O6IJupokjdgb9MsP5C .f8ocxVK1sVIAfJqfu6Tf {
		display: inline-block;
		font-size: .16rem;
		line-height: 1;
		max-width: .9rem;
		overflow: hidden;
		padding-top: .2rem;
		text-align: center;
		text-overflow: ellipsis
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .OcuOujWTdm6_XyP_Zkzv .Z9Z8NJqAL5GmtGLeXZWA .C3O6IJupokjdgb9MsP5C:first-child {
		margin-left: .3rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .OcuOujWTdm6_XyP_Zkzv .Z9Z8NJqAL5GmtGLeXZWA>div {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		min-width: .75rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .OcuOujWTdm6_XyP_Zkzv .Z9Z8NJqAL5GmtGLeXZWA>div:not(:last-child) {
		margin-right: .3rem
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .OcuOujWTdm6_XyP_Zkzv .Z9Z8NJqAL5GmtGLeXZWA>div>img {
		border-radius: 50%
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .OcuOujWTdm6_XyP_Zkzv .Z9Z8NJqAL5GmtGLeXZWA>div>span {
		display: inline-block;
		font-size: .16rem;
		line-height: 1;
		max-width: .9rem;
		overflow: hidden;
		padding-top: .1rem;
		text-align: center;
		text-overflow: ellipsis
	}

	html .y9ILWSVwXtmlSiqyeCz8 .MXo4zhinE1a7NGQqvRB2 .pRz6fwuM33E7Ucvxei1S .CxK9TRqEL_yCa_8Icpln {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		color: var(--theme-primary-color);
		display: -ms-flexbox;
		display: flex;
		font-size: .24rem;
		height: .94rem;
		justify-content: center;
		width: 100%
	}

	html .y9ILWSVwXtmlSiqyeCz8 .TYMTtoXU0sibE7GDluu1 {
		-webkit-animation: d5a73TD0H8Hpx3wOlcSQ .5s ease;
		animation: d5a73TD0H8Hpx3wOlcSQ .5s ease;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}

	html .y9ILWSVwXtmlSiqyeCz8 .xO6klY8bh9TGEFsaMAPm {
		-webkit-animation: mX4IY2Ww97kS9grBYf_t .5s ease;
		animation: mX4IY2Ww97kS9grBYf_t .5s ease;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}

	.y9ILWSVwXtmlSiqyeCz8 .B9LmeSQOfyyQDEZqRAdm {
		background-color: var(--theme-main-bg-color);
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		margin: .2rem 0;
		padding: .4rem
	}
	.hBXG33WPJjXOwqg4Qi91 {
	    background-color: var(--theme-main-bg-color);
	    border-radius: .1rem;
	    -webkit-box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.06);
	    box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.06);
	    height: auto;
	    padding-top: 0!important;
	    padding: .2rem
	}
	
	.hBXG33WPJjXOwqg4Qi91:not(:first-child) {
	    margin: .2rem 0 0
	}
	
	.hBXG33WPJjXOwqg4Qi91 .TUSPUZVxUlSsX6cnNkWa {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    color: var(--theme-text-color-lighten);
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .2rem;
	    height: .65rem;
	    justify-content: center;
	    text-align: center
	}
	
	.hBXG33WPJjXOwqg4Qi91 .xmTBy8GFpn1k9Bb9QLt_ {
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    font-size: .16rem
	}
	
	.hBXG33WPJjXOwqg4Qi91 .xmTBy8GFpn1k9Bb9QLt_ .NyWAKBXfO74hdgDTMrVS {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    border-radius: .06rem!important;
	    color: var(--theme-text-color-lighten);
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: center;
	    min-height: .5rem
	}
	
	.hBXG33WPJjXOwqg4Qi91 .xmTBy8GFpn1k9Bb9QLt_ .NyWAKBXfO74hdgDTMrVS span {
	    margin-left: .04rem;
	    text-align: center
	}
	
	.hBXG33WPJjXOwqg4Qi91 .xmTBy8GFpn1k9Bb9QLt_ .NyWAKBXfO74hdgDTMrVS span:nth-child(2) {
	    color: var(--theme-text-color-darken)
	}
	
	.hBXG33WPJjXOwqg4Qi91 .xmTBy8GFpn1k9Bb9QLt_ .NyWAKBXfO74hdgDTMrVS:nth-child(odd) {
	    background-color: var(--theme-bg-color);
	    border-radius: .06rem 0 0 .06rem
	}
	
	html .hBXG33WPJjXOwqg4Qi91 .TUSPUZVxUlSsX6cnNkWa {
	    font-size: .24rem;
	    height: .75rem
	}
	
	html .hBXG33WPJjXOwqg4Qi91 .xmTBy8GFpn1k9Bb9QLt_ .NyWAKBXfO74hdgDTMrVS {
	    font-size: .24rem;
	    line-height: .7rem
	}
	
	html .hBXG33WPJjXOwqg4Qi91 .xmTBy8GFpn1k9Bb9QLt_ .NyWAKBXfO74hdgDTMrVS:nth-child(odd) {
	    border-radius: .1rem
	}
	.y9ILWSVwXtmlSiqyeCz8 .RDVBXpScbYLUeqahO4q5 .bTkyl5eQr3uEIZ8Dgnfw {
		color: var(--theme-text-color-lighten)
	}

	.y9ILWSVwXtmlSiqyeCz8 .RDVBXpScbYLUeqahO4q5 .bTkyl5eQr3uEIZ8Dgnfw .TMR0c4Pr6f5L9E34buzp {
		font-size: .2rem;
		line-height: 1.3
	}

	.y9ILWSVwXtmlSiqyeCz8 .RDVBXpScbYLUeqahO4q5 .bTkyl5eQr3uEIZ8Dgnfw .TMR0c4Pr6f5L9E34buzp span {
		cursor: pointer
	}

	.y9ILWSVwXtmlSiqyeCz8 .RDVBXpScbYLUeqahO4q5 .bTkyl5eQr3uEIZ8Dgnfw .ukboJfGL8gpiGdbhBxpJ {
		font-size: .2rem;
		line-height: 1.3
	}

	.y9ILWSVwXtmlSiqyeCz8 .RDVBXpScbYLUeqahO4q5 .bTkyl5eQr3uEIZ8Dgnfw>div>span {
		color: var(--theme-text-color)
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU {
		margin-top: .2rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .Uurf2eE1EvJ8taIjSBAx:nth-child(2n) .J9w_6tuODPdBjAKYjzRB {
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .Uurf2eE1EvJ8taIjSBAx:nth-child(2n) .T5W9naENOMXV_994Fe8b {
		-webkit-transform: rotateY(180deg);
		transform: rotateY(180deg)
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .Uurf2eE1EvJ8taIjSBAx:nth-child(2n) .UCM1fIOkrXgoV8tKpWaA {
		-ms-flex-pack: start;
		justify-content: flex-start
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .Uurf2eE1EvJ8taIjSBAx:nth-child(2n) .UCM1fIOkrXgoV8tKpWaA svg {
		margin-left: .87rem;
		margin-right: 0
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .J9w_6tuODPdBjAKYjzRB {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg {
		position: relative;
		width: 2.12rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .tVWMurTJ0hgGdLJOcBwM {
		position: relative
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .tVWMurTJ0hgGdLJOcBwM .cd4GJbR3Wd3qG_LfsF9Q {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .a7rMwyv8RGb4nno93pZw {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		bottom: .14rem;
		color: var(--theme-text-color-activities-text);
		display: -ms-flexbox;
		display: flex;
		font-size: .26rem;
		height: .6rem;
		justify-content: center;
		left: 0;
		padding: 0 .36rem;
		position: absolute;
		right: 0
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .DHkNZATZWTkEcsdUArGN {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-secondary-color-error);
		display: -ms-flexbox;
		display: flex;
		height: .58rem;
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: .6rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .DHkNZATZWTkEcsdUArGN>div {
		padding: 0 .2rem;
		width: 100%
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .BtEAp6BjEEOLr00dCvTp {
		color: var(--theme-text-color-lighten) !important
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .YRQ2J6iR5UsUbKRNAOVQ {
		position: relative
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .YRQ2J6iR5UsUbKRNAOVQ .cdnExGkUiUfiXoGqHOBT {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		left: .85rem;
		position: absolute;
		top: .2rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .YRQ2J6iR5UsUbKRNAOVQ .j2sKlwXzVOYqvsajUckQ {
		top: .58rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .NCbu1Mch6PC7N3vHu7nr {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		font-size: .2rem;
		height: .5rem;
		justify-content: center;
		width: inherit;
		word-break: break-all
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .NCbu1Mch6PC7N3vHu7nr span {
		font-size: .2rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .cdTm7WAp1DzSFJtpaZBg .cJ15xW5ihZdp5DhKL6rX {
		color: var(--theme-secondary-color-finance) !important
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA {
		-ms-flex-pack: end;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-end;
		margin-top: .2rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA svg {
		margin-right: .87rem;
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg)
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA.uXgHB6pqx_7dqw8J3tIz {
		margin-bottom: .2rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .T5W9naENOMXV_994Fe8b {
		display: block;
		padding: 0 .265rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .T5W9naENOMXV_994Fe8b,
	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA {
		color: var(--theme-secondary-color-finance)
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .T5W9naENOMXV_994Fe8b svg,
	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA svg {
		height: .38rem;
		width: .38rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .T5W9naENOMXV_994Fe8b.WPc2UbH4eWvd7rjuL71e,
	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .UCM1fIOkrXgoV8tKpWaA.WPc2UbH4eWvd7rjuL71e {
		color: var(--theme-text-color-lighten)
	}

	.y9ILWSVwXtmlSiqyeCz8 .eOKOIr3zSV1KpjZLu6bU .FwAtuvnbsAew7ub5himj {
		margin-top: -.4rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .msEOVVtTMAoUtALdT2sA {
		color: var(--theme-text-color-lighten);
		font-size: .16rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .l4QR1CLdRY4eHPkLnrTj {
		color: var(--theme-secondary-color-finance);
		font-size: .16rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .mSAHm2ZEeMguccNUkCfy {
		background-color: var(--theme-main-bg-color);
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		height: auto;
		padding-top: 0 !important;
		padding: .2rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .mSAHm2ZEeMguccNUkCfy:not(:first-child) {
		margin: .2rem 0 0
	}

	.y9ILWSVwXtmlSiqyeCz8 .mSAHm2ZEeMguccNUkCfy .RsF419YaltdgXdOYssLA {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		font-size: .2rem;
		height: .65rem;
		justify-content: center
	}

	.y9ILWSVwXtmlSiqyeCz8 .mSAHm2ZEeMguccNUkCfy .USQnz1ZNZDBrtxSu3oKT {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		font-size: .16rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .mSAHm2ZEeMguccNUkCfy .USQnz1ZNZDBrtxSu3oKT .CbGxrJFW8nwDH_FIXeOl {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		height: .5rem;
		justify-content: space-between
	}

	.y9ILWSVwXtmlSiqyeCz8 .mSAHm2ZEeMguccNUkCfy .USQnz1ZNZDBrtxSu3oKT .CbGxrJFW8nwDH_FIXeOl span {
		text-align: center;
		width: 50%
	}

	.y9ILWSVwXtmlSiqyeCz8 .mSAHm2ZEeMguccNUkCfy .USQnz1ZNZDBrtxSu3oKT .CbGxrJFW8nwDH_FIXeOl span:nth-child(2) {
		color: var(--theme-text-color-darken)
	}

	.y9ILWSVwXtmlSiqyeCz8 .mSAHm2ZEeMguccNUkCfy .USQnz1ZNZDBrtxSu3oKT .CbGxrJFW8nwDH_FIXeOl:nth-child(odd) {
		background-color: var(--theme-bg-color);
		border-radius: .06rem 0 0 .06rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .BPwSeHh4a5nHxKrPpu8r {
		padding-bottom: .2rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .GqBz_dj3fSOszS9C2crl {
		font-size: .16rem;
		height: .4rem;
		line-height: .4rem;
		padding: 0;
		width: 1rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .GqBz_dj3fSOszS9C2crl.ant-btn-loading {
		padding: 0 !important
	}

	.y9ILWSVwXtmlSiqyeCz8 .GqBz_dj3fSOszS9C2crl.ant-btn-loading>.anticon-loading {
		display: none
	}

	.y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X>:first-child {
		color: var(--theme-text-color-lighten);
		font-size: .2rem;
		height: .29rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X>:nth-child(2) {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex
	}

	.y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X>:nth-child(2) input {
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .1rem;
		color: var(--theme-text-color);
		cursor: auto;
		font-size: .2rem;
		height: .5rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X>:nth-child(2)>span {
		margin-left: .2rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X>:nth-child(2)>span button {
		font-size: .16rem;
		height: .4rem;
		width: 1rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		width: 100%
	}

	.y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X div:first-child {
		margin-right: .2rem;
		width: .8rem
	}

	.y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X .VkVwJXWWH4jMnpcewtcg {
		-ms-flex: 1;
		flex: 1;
		position: relative
	}

	.y9ILWSVwXtmlSiqyeCz8 .VDOS2mZVH7b1TFbAvt5X .VkVwJXWWH4jMnpcewtcg .e2fEk560rHH7reZMW__w {
		font-size: .2rem;
		position: absolute;
		right: .2rem
	}

	.MZG2AG46nbxCf7Pj94bu {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		background: var(--theme-main-bg-color);
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		display: -ms-flexbox;
		display: flex;
		font-size: .2rem;
		height: 1rem;
		justify-content: flex-start;
		padding: 0 .2rem;
	}

	.cX3I4wr8lagPbzMNAc5i {
		-ms-flex-align: start;
		align-items: flex-start;
		display: -ms-flexbox;
		display: flex;
		min-height: 1.35rem;
		padding-bottom: .06rem;
		padding-top: .2rem
	}

	.cX3I4wr8lagPbzMNAc5i .my-scrollbar-bar.my-scrollbar-horizontal {
		bottom: 0
	}

	.cX3I4wr8lagPbzMNAc5i>div {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		min-width: .95rem
	}

	.cX3I4wr8lagPbzMNAc5i>div:not(:last-child) {
		margin-right: .1rem
	}

	.cX3I4wr8lagPbzMNAc5i>div>img {
		border-radius: 50%
	}

	.my-scrollbar-content {
		overflow-y: scroll;
	}

	.ant-select-selection {
		border: 1px solid var(--theme-color-line);
	}

	.ant-select-selection:hover {
		border-color: var(--theme-ant-primary-color-13);
		border-right-width: 1px !important
	}

	.cX3I4wr8lagPbzMNAc5i>div>span {
		word-wrap: break-word;
		display: inline-block;
		font-size: .22rem;
		line-height: 1.15;
		max-width: 1.1rem;
		padding-top: .1rem;
		text-align: center;
		color: var(--theme-text-color);
	}

	.XGEOwGXgDL9a2SebPqvj {
		color: var(--theme-secondary-color-finance);
		width: 1rem;
		height: 1rem;
		position: absolute;
	}

	.t0HiyFKEvEAQH2C_LBRk span.isBigAgent {
		color: var(--theme-secondary-color-help-text-color);
		right: .09rem
	}

	.t0HiyFKEvEAQH2C_LBRk span {
		bottom: -.01rem;
		color: var(--theme-primary-font-color);
		font-size: .18rem;
		line-height: .32rem;
		position: absolute;
		right: .04rem
	}

	.cX3I4wr8lagPbzMNAc5i .zsbbPf1TFxnlj15CZdFo {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		border: .01rem solid var(--theme-color-line);
		border-radius: 50%;
		color: var(--theme-primary-color);
		display: -ms-flexbox;
		display: flex;
		font-size: .36rem;
		height: .7rem;
		justify-content: center;
		padding: 0;
		width: .7rem
	}

	.tutorialDetail {
		border-radius: .14rem;
		padding: .2rem
	}

	.tutorialDetail {
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .1rem;
		color: var(--theme-text-color-darken);
		font-size: .24rem;
		line-height: .4rem;
		padding: .3rem;
		margin-bottom: .5rem;
	}

	.COsess8CMJ_BLuGwYYdj {
		color: var(--theme-primary-color);

	}

	.goDRiiBsuEuXD3W1NphN {
		position: relative;
	}

	.ant-btn,
	.ant-select-dropdown {
		border-radius: .14rem
	}

	html .ant-btn.ant-btn-primary {
		background-color: var(--theme-primary-color);
		border-color: var(--theme-primary-color)
	}

	html .ant-btn.ant-btn-primary {
		color: var(--theme-primary-font-color);
		text-shadow: none
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0 {
		cursor: pointer;
		height: 100%;
		position: relative
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:first-child {
		display: inline-block;
		height: .8rem;
		width: 1.04rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf {
		-ms-flex-align: start;
		-ms-flex-pack: justify;
		align-items: flex-start;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: .2rem .2rem 0
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix {
		margin-right: .2rem;
		width: 1.5rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix .ant-btn-link {
		background-color: var(--theme-primary-color);
		border-radius: 0 0 .14rem .14rem;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		color: var(--theme-primary-font-color);
		font-size: .24rem;
		height: auto;
		min-height: .4rem;
		padding: 0px;
		padding-bottom: .05rem;
		white-space: pre-wrap;
		/* width: 1.5rem; */
		text-align: center;
		word-break: break-word;
		cursor: pointer;
		line-height: 1.499;
		display: inline-block;
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix .ant-btn-link[disabled] {
		background-color: var(--theme-disabled-bg-color) !important
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix ._197iSp6iuweVeX9jjWqU {
		-ms-flex-pack: end;
		background: #fff;
		border: .01rem solid var(--theme-color-line);
		border-radius: .14rem .14rem .2rem .2rem;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		justify-content: flex-end;
		min-height: 1.9rem;
		position: relative;
		width: 1.5rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix ._197iSp6iuweVeX9jjWqU canvas {
		height: 1.3rem !important;
		margin: .1rem;
		width: 1.3rem !important;
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix ._197iSp6iuweVeX9jjWqU .LKKxPSsoHwTWKNcyj1c8 {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		height: 1.5rem;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1>.uQRA1q9mzX1o2pAJ5uZA {
		-ms-flex-pack: justify;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		font-size: .2rem;
		height: .29rem;
		justify-content: space-between;
		margin-bottom: .1rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1>.uQRA1q9mzX1o2pAJ5uZA>span:nth-child(2) {
		color: var(--theme-primary-color);
		cursor: pointer
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE {
		position: relative;
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select {
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection--single {
		border-radius: .1rem;
		color: var(--theme-text-color);
		height: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection__rendered {
		font-size: .2rem;
		padding: .2rem 0 .2rem .1rem;
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection-selected-value {
		white-space: normal
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-arrow {
		position: absolute;
		right: 0;
		top: 0
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE #myLink-suffixIcon {
		text-wrap: nowrap;
		color: var(--theme-primary-color);
		cursor: pointer;
		font-size: .24rem;
		position: absolute;
		right: 0;
		top: -.25rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .COsess8CMJ_BLuGwYYdj {
		font-size: .24rem;
		position: absolute;
		right: .2rem;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM {
		width: 4.9rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .uQRA1q9mzX1o2pAJ5uZA {
		font-size: .24rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE {
		position: relative;
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select {
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection--single {
		border-radius: .1rem;
		height: 100%;
		max-width: 5rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection__rendered {
		font-size: .22rem;
		line-height: normal;
		margin: 0;
		overflow: hidden;
		padding: .2rem .59rem .2rem .2rem;
		text-overflow: ellipsis;
		width: auto
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .COsess8CMJ_BLuGwYYdj {
		font-size: .3rem;
		position: absolute;
		right: .2rem;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf {
		-ms-flex-align: start;
		-ms-flex-pack: justify;
		align-items: flex-start;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: .24rem;
		justify-content: space-between;
		margin-top: .2rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: .1rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div>span {
		color: var(--theme-text-color-darken)
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div>span.dOhCK6tlyL9yiDmj_NGc {
		color: var(--theme-text-color-lighten)
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div .COsess8CMJ_BLuGwYYdj {
		color: var(--theme-primary-color);
		margin-left: .1rem
	}


	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:nth-child(2) {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: var(--theme-primary-color);
		border-radius: 50%;
		color: var(--theme-primary-font-color);
		cursor: pointer;
		display: -ms-flexbox;
		display: flex;
		height: .24rem;
		justify-content: center;
		left: .82rem;
		position: absolute;
		top: .05rem;
		width: .24rem
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:nth-child(2) svg {
		color: var(--theme-primary-font-color);
		font-size: .12rem
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 {
		padding: .2rem .2rem .2rem .3rem
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .FAnV4xyvhwu6V8VvjxOg,
	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .mwkrCf7T9Q6EuRxoGPvN {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .FAnV4xyvhwu6V8VvjxOg span:first-child,
	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .mwkrCf7T9Q6EuRxoGPvN span:first-child {
		display: inline-block;
		padding-right: .1rem
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .mwkrCf7T9Q6EuRxoGPvN {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .mwkrCf7T9Q6EuRxoGPvN>span:last-child {
		color: var(--theme-text-color-darken)
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .FAnV4xyvhwu6V8VvjxOg {
		color: var(--theme-text-color-lighten);
		margin-bottom: .1rem
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .FAnV4xyvhwu6V8VvjxOg>span:last-child {
		color: var(--theme-text-color-darken)
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .H2Y9pGRG8VdCF50ZdZlE {
		-ms-flex-negative: 0;
		color: var(--theme-primary-color);
		flex-shrink: 0;
		font-size: .24rem;
		line-height: 1
	}

	.MZG2AG46nbxCf7Pj94bu .KzvcBdzMHhzwjIUa04tc {
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding: .2rem .2rem .2rem .3rem
	}

	.MZG2AG46nbxCf7Pj94bu .KzvcBdzMHhzwjIUa04tc .FmRERCI89HukhH03x3QV {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex
	}

	.MZG2AG46nbxCf7Pj94bu .KzvcBdzMHhzwjIUa04tc .currencyAmount {
		font-size: .24rem
	}

	.MZG2AG46nbxCf7Pj94bu .p0wYtn0oOg74hhZSLc7V {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
		padding: .2rem .1rem .2rem 0;
		width: 2.4rem
	}

	.MZG2AG46nbxCf7Pj94bu .p0wYtn0oOg74hhZSLc7V button {
		height: .4rem;
		padding: 0;
		width: 1rem
	}

	.nfz1NBFeLVm6IIOo2CLj {
		background: none;
		overflow: scroll;
		width: 100%
	}

	.ant-spin-container {
		position: relative;
		-webkit-transition: opacity .3s;
		transition: opacity .3s
	}

	.ant-spin-container:after {
		background: #fff;
		bottom: 0;
		content: "";
		display: none\9;
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-transition: all .3s;
		transition: all .3s;
		width: 100%;
		z-index: 10
	}

	.nfz1NBFeLVm6IIOo2CLj .currencyAmount .suffix {
		color: var(--theme-text-color-darken)
	}

	.nfz1NBFeLVm6IIOo2CLj .QBeJPwd_uejjKLAsjWcb {
		background-color: var(--theme-main-bg-color);
		border-radius: 0 0 .14rem .14rem
	}

	.nfz1NBFeLVm6IIOo2CLj .Zw36nTThA8kC3264AaHK {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		font-size: .3rem;
		justify-content: space-between;
		padding: .2rem 0;
		width: 100%
	}

	.nfz1NBFeLVm6IIOo2CLj .hrp2W0A6qvg1zg4ysky3 {
		margin: .15rem .15rem 0;
		padding: .05rem
	}

	.nfz1NBFeLVm6IIOo2CLj .QV03qBSNYehJplfhfCcw {
		overflow: hidden;
		padding: 0 .2rem .2rem
	}

	.MZG2AG46nbxCf7Pj94bu {
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom: .01rem solid var(--theme-color-line);
		border-radius: .14rem .14rem 0 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: .22rem;
		height: auto
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0 {
		margin-right: .2rem
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:first-child {
		display: inline-block;
		width: 1.3rem
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:first-child>img {
		width: 100% !important
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:nth-child(2) {
		height: .24rem;
		left: 1.12rem;
		position: absolute;
		top: .05rem;
		width: .24rem
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:nth-child(2) svg {
		font-size: .16rem
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju {
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding: .2rem 0
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .tbJe4aWRACpPqK8U19l3 {
		-ms-flex-align: start;
		-ms-flex-pack: justify;
		align-items: flex-start;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: .22rem;
		justify-content: space-between;
		margin-top: .2rem;
		padding: 0;
		width: 100%
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .tbJe4aWRACpPqK8U19l3 label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		font-size: .22rem;
		justify-content: space-between;
		padding: 0
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc .FmRERCI89HukhH03x3QV {
		-ms-flex-positive: 1;
		flex-grow: 1
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc button {
		font-size: .2rem;
		height: .5rem;
		line-height: 1.3;
		padding: 0;
		width: 1.2rem
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc button span {
		word-wrap: break-word;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		padding: 0 !important;
		overflow: hidden;
		padding-right: 0 !important;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: pre-wrap
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc .ant-btn-success {
		margin-right: .2rem;
		background-color: var(--theme-disabled-bg-color);
		border-color: var(--theme-disabled-bg-color);
		color: var(--theme-disabled-font-color);
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc .currencyAmount {
		font-size: .22rem
	}

	[data-skin-layout="9"][data-device=desktop] .MZG2AG46nbxCf7Pj94bu,
	[data-skin-layout="9"][data-device=desktop] .ant-calendar-date-panel,
	[data-skin-layout="9"][data-device=desktop] .boxShadow,
	[data-skin-layout="9"][data-device=desktop] .myTitle {
		-webkit-box-shadow: 0 0 .1rem 0 #00d9ff !important;
		box-shadow: 0 0 .1rem 0 #00d9ff !important
	}

	.M0HpRLR01mBt_PBm3WJL {
		-ms-flex-align: center;
		align-items: center;
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .1rem;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: .2rem;
		min-height: 1.8rem;
		padding: .2rem;
		width: 7rem
	}

	.M0HpRLR01mBt_PBm3WJL em {
		color: var(--theme-secondary-color-finance)
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		line-height: 1.64;
		margin-left: .2rem;
		width: 100%
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p>span {
		margin-right: .05rem;
		text-align: left
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p>span:lang(zh_CN),
	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p>span:lang(zh_TW) {
		margin-right: auto;
		width: 50%
	}

	.M0HpRLR01mBt_PBm3WJL>div:first-child {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-left: .5rem
	}


	.M0HpRLR01mBt_PBm3WJL {
		border-radius: .14rem;
		position: relative;
		width: 100%
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta {
		margin-left: .3rem
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p {
		-ms-flex-pack: start;
		justify-content: flex-start;
		text-align: left
	}
</style>
