<template>
	<div class="promote ">
		<!-- 
			
			{{ $t('security._Confirmenova') }}
			
			
		-->
		<van-nav-bar :title="$t('security._SenhadeSaque')" left-arrow fixed placeholder @click-left="onClickLeft" />
		<div class="I9T4OGduED94X6ahO3Qj">
			<div class="DRcvr_ui3dMkJZpnft5Z">{{ $t('security._Paraase') }}</div>
			<div class="Xq4eL9kY4HGkgyNzZTx4">{{ $t('security._Definasuasenhadesaque') }}</div>
			<div class="Ou1aviPriFX421w97dGA">
				<span>{{ $t('security._NovaSenhadeSaque') }}</span>
				<i @click="isShowNum=!isShowNum" :class="{
						isShowNum:isShowNum
					}">
					<svg-icon className="ZaAWGnvFuhPwlBk40od_"
						:icon-class="`${isShowNum?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}`" />
				</i>
			</div>
			<div class="conterArr">
				<ant-input-password :isShow="isShowNum"  @change="newPassword" style="margin-bottom: .5rem;"></ant-input-password>
				<div v-if="isShowPasword" class="ant-form-explain">{{ $t('security._Nãomesmosounúmeroscontínuos') }}</div>
			</div>
			<div class="Ou1aviPriFX421w97dGA">
				<span>{{ $t('security._Confirmenova') }}</span>
				<i @click="isShowNumConfirm=!isShowNumConfirm" :class="{
						isShowNumConfirm:isShowNumConfirm
					}">
					<svg-icon className="ZaAWGnvFuhPwlBk40od_"
						:icon-class="`${isShowNumConfirm?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}`" />
				</i>
			</div>
			<div class="conterArr">
				<ant-input-password style="margin-bottom: .5rem;"   @change="new_passwordConfirm"  :isShow='isShowNumConfirm'></ant-input-password>
				<div v-if="isShowNumConfirm" class="ant-form-explain">{{ $t('security._Nãomesmosounúmeroscontínuos') }}</div>
				<div v-else-if="consistent" class="ant-form-explain">{{ $t('security._Senhaincorreta') }}</div>
			</div>
			<div class="FoD6bH3V5A47RnLkco6A">
				{{ $t('security._Atenção') }}
			</div>
		</div>
		<div @click="withdrawPassword" class="Xhr_XJt3jLTtAL8pC16D verification-btn__group wg-fixed-no-desktop"><button type="button"
				class="ant-btn ant-btn-primary ant-btn-block verification-btn__next"
				>
				{{ $t('security._Confirmar') }}
				</button>
		</div>
	</div>

</template>

<script>
	// ant-input-password
	import antInputPassword from "@/components/ant-input-password/index.vue"
	import {withdrawPassword} from "@/apis/modules/login.js"
	import { Toast } from 'vant';
	import { mapState } from 'vuex';
	// withdrawPassword
	export default {
		components: {
			antInputPassword
		},
		computed:{
			...mapState(['popList','userInfo','moneyInfo'])
		},
		data() {
			return {
				codeValue: '',
				isShowNum: false,
				isShowNumConfirm:false,
				consistent:false,
				isShowPasword: false,
				inputFocus: false,
				new_password:'',
				new_password_confirm:'',
			};
		},
		methods: {
			withdrawPassword(){
					if(this.new_password_confirm.length!=6){
						return false;
					}
					if(this.new_password.length!=6){
						return false;
					}
					withdrawPassword({
						type:'0',
						withdraw_password:this.new_password,
						withdraw_password_confirm:this.new_password_confirm
					}).then(({msg,code})=>{
						Toast(msg)
						if(code===0){
							// this.userInfo.set_withdraw_password
							this.$store.commit('userInfo',{
								...this.userInfo,
								set_withdraw_password:1
							})
			  
							let tim=setTimeout(()=>{
								clearTimeout(tim)
								// @click=""
								this.openUrl('withdraw',{current:2})
								// this.$router.go(-1);
								// withdraw?current=3
							},1000)
						}
					})
			},
			areSixDigitsTheSame(str) {
			  const regex = /(\d)\1{5}/;
			  return regex.test(str);
			},
			newPassword(e){
				this.isShowPasword=this.areSixDigitsTheSame(e)
				this.new_password=e;
			},
			new_passwordConfirm(e){
				this.isShowNumConfirm=this.areSixDigitsTheSame(e);
				if(e.length==6&& this.new_password!=e){
					this.consistent=true;
				}else{
					this.consistent=false;
				}
				this.new_password_confirm=e;
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		},
	}
</script>

<style scoped>
	.I9T4OGduED94X6ahO3Qj {
		padding: .2rem;
		padding-top: .4rem;
	}

	.DRcvr_ui3dMkJZpnft5Z {
		color: var(--theme-secondary-color-success);
		margin-bottom: .3rem;
		line-height: 1.5;
		text-align: center;
		font-size: .22rem;

	}

	.Xq4eL9kY4HGkgyNzZTx4 {
		font-size: .24rem;
		margin-bottom: .4rem;
		color: var(--theme-text-color-darken);
		margin-top: 0;
	}

	.Ou1aviPriFX421w97dGA {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: .22rem;
		color: var(--theme-text-color-darken);
		height: .32rem;
		margin-bottom: .15rem;
	}

	.Ou1aviPriFX421w97dGA i {
		color: var(--theme-color-line);
	}

	.Ou1aviPriFX421w97dGA i.isShowNum {
		color: var(--theme-primary-color);
	}

	.FoD6bH3V5A47RnLkco6A {
		font-size: .22rem;
		margin-bottom: .5rem;
		color: var(--theme-secondary-color-finance);
		line-height: 1.75;
	}

	.conterArr {
		position: relative;
	}

	.conterArr .ant-form-explain {
		color: var(--theme-secondary-color-error);
		font-size: .18rem;
		position: absolute;
		height: .4rem;
		margin-bottom: -.4rem;
		bottom: -.1rem;
	}

	.conterArr .ant-form-explain::before {
		background-color: var(--theme-secondary-color-error);
		content: "";
		height: 0.18rem;
		margin-right: 0.1rem;
		visibility: visible;
		width: 0.18rem;
		display: inline-block;
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
		background-size: 13.068rem 12.753rem;
		border-radius: 50%;
		background-position: -12.888rem -3.825rem;
	}
	.verification-btn__group {
	    -ms-flex-pack: center;
	    background-color: var(--theme-main-bg-color);
	    bottom: 0;
	    -webkit-box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
	    box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
	    display: -ms-flexbox;
	    display: flex;
	    height: 1.3rem;
	    justify-content: center;
	    padding-top: .2rem;
	    position: fixed;
		left: auto; 
	    /* width: var(--theme-max-width) */
		width: 100%;
		max-width:var(--theme-max-width) ;
	}
.Xhr_XJt3jLTtAL8pC16D button {
    font-size: .24rem;
    height: .7rem;
	 width: 7.1rem!important;
	  background-color: var(--theme-primary-color);
	     border-color: var(--theme-primary-color)
}

</style>
